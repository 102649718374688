import imgBrave from '../images/thumbs/bg-bravelydefault.jpg'
import imgCutty from '../images/thumbs/bg-cutty.png'
import imgEvocalize from '../images/thumbs/bg-evocalize.png'
import imgGlacier from '../images/thumbs/bg-glacier.jpg'
import imgHVC from '../images/thumbs/bg-hvc.png'
import imgHistory from '../images/thumbs/bg-history.png'
import imgIRC from '../images/thumbs/bg-jungle.jpg'
import imgIysl from '../images/thumbs/bg-condom.jpg'
import imgMTA from '../images/thumbs/bg-mta.png'
import imgMario from '../images/thumbs/bg-mario.jpg'
import imgNissan from '../images/thumbs/bg-nissan.png'
import imgOgilvy from '../images/thumbs/bg-ogilvyone.png'
import imgSpace from '../images/thumbs/bg-space.png'
import imgTfc from '../images/thumbs/bg-tfc.png'
import imgWoods from '../images/thumbs/bg-woods.jpg'
import imgOrtho from '../images/thumbs/bg-me-orthopedics.jpg'
import imgCovid19 from '../images/thumbs/bg-me-covid19.jpg'
import imgCancer from '../images/thumbs/bg-me-cancer.jpg'

const data = [
  {
    id: 0,
    img: imgIRC,
    title: 'IRC',
    subTitle: 'Web App',
    slug: 'outcomes-and-evidence',
  },
  {
    id: 8,
    img: imgMario,
    title: 'Nintendo',
    subTitle: 'Website',
    slug: 'mario-party-island-tour',
  },
  {
    id: 13,
    img: imgGlacier,
    title: 'ETA',
    subTitle: 'Web App',
    slug: 'expedition-travel-advisor',
  },
  {
    id: 1,
    img: imgBrave,
    title: 'Nintendo',
    subTitle: 'Website',
    slug: 'bravely-default',
  },
  {
    id: 2,
    img: imgHistory,
    title: 'History',
    subTitle: 'Native App',
    slug: 'history-channel',
  },
  {
    id: 3,
    img: imgIysl,
    title: "MTV",
    subTitle: 'Web App',
    slug: 'its-your-sex-life',
  },
  {
    id: 4,
    img: imgWoods,
    title: 'Woods Baggot',
    subTitle: 'Website',
    slug: 'woods-baggot',
  },
  {
    id: 5,
    img: imgHVC,
    title: 'Credit Suisse',
    subTitle: 'Web App',
    slug: 'credit-suisse',
  },
  {
    id: 7,
    img: imgNissan,
    title: 'Nissan',
    subTitle: 'Web App',
    slug: 'nissan',
  },
  {
    id: 9,
    img: imgTfc,
    title: 'TFC',
    subTitle: 'Web App',
    slug: 'tfcornerstone',
  },
  {
    id: 10,
    img: imgCutty,
    title: 'Cutty Sark',
    subTitle: 'Website',
    slug: 'cutty-sark',
  },
  {
    id: 11,
    img: imgMTA,
    title: "MTA",
    subTitle: 'Native App',
    slug: 'mta',
  },
  {
    id: 12,
    img: imgOgilvy,
    title: 'Ogilvy',
    subTitle: 'Website',
    slug: 'ogilvy-one',
  },
  {
    id: 14,
    img: imgSpace,
    title: 'Open Source',
    subTitle: 'Design & Dev',
    slug: 'development-dashboards',
  },
  {
    id: 15,
    img: imgEvocalize,
    title: 'Evocalize',
    subTitle: 'Design',
    slug: 'evocalize',
  },
  {
    id: 16,
    img: imgOrtho,
    title: 'M|E Orthopedics',
    subTitle: 'Development',
    slug: 'montefiore-orthopedics',
  },
  {
    id: 16,
    img: imgCovid19,
    title: 'Covid-19',
    subTitle: 'Development',
    slug: 'montefiore-covid19',
  },
  {
    id: 17,
    img: imgCancer,
    title: 'M|E Cancer',
    subTitle: 'Engineering',
    slug: 'montefiore-cancer-center',
  },
]

export default data

//===================================================
// Archive
//===================================================
//
// import imgClub from '../images/thumbs/bg-club.png'
// {
//   id: 16,
//   img: imgClub,
//   title: 'Club Nintendo',
//   subTitle: 'Website',
//   slug: 'club-nintendo',
// },
//
//===================================================
