import React from 'react'
import { Link } from '@reach/router'
import styled from 'styled-components'

const StyledButton = styled(Link)`
  background: ${({ solid }) => solid === "yes" ? "#907E63" : "transparent"};
  border: 0.2rem solid rgba(255,255,255,0.5);
  border-radius: 0.2rem;
  color: ${({ solid }) => solid === "yes" ? "white" : "#fff"};
  display: inline-flex;
  font-family: 'Ubuntu';
  font-size: 1.3rem;
  font-weight: 700;
  height: 5rem;
  justify-content: center;
  letter-spacing: 0.1rem;
  line-height: 4.6rem;
  padding: 0 1rem;
  text-align: center;
  text-transform: uppercase;
  transition: background 0.4s, color 0.4s;

  @media (min-width: 500px) {
    padding: 0 4rem;
  }

  &:hover {
    background: ${({ solid }) => solid === "yes" ? "transparent" : "rgba(255,255,255,0.3)"};
    color: ${({ solid }) => solid === "yes" ? "#907E63" : "white"};
    cursor: pointer;
  }
`

const StyledButtonA = styled.a`
  background: ${({ solid }) => solid === "yes" ? "#907E63" : "transparent"};
  border: 0.2rem solid rgba(255,255,255,0.5);
  border-radius: 0.2rem;
  color: ${({ solid }) => solid === "yes" ? "white" : "#fff"};
  display: inline-flex;
  font-family: 'Ubuntu';
  font-size: 1.3rem;
  font-weight: 700;
  height: 5rem;
  justify-content: center;
  letter-spacing: 0.1rem;
  line-height: 4.6rem;
  padding: 0 1rem;
  text-align: center;
  text-transform: uppercase;
  transition: background 0.4s, color 0.4s;

  @media (min-width: 500px) {
    padding: 0 4rem;
  }

  &:hover {
    background: ${({ solid }) => solid === "yes" ? "transparent" : "rgba(255,255,255,0.3)"};
    color: ${({ solid }) => solid === "yes" ? "#907E63" : "white"};
    cursor: pointer;
  }
`

const Button = ({
  as = "",
  children = "",
  solid = "yes",
  text = "",
  url = "#"
}) => {
  return (
    <>
      {
        as === "a"
        ? (
          <StyledButtonA as={as} href={url} solid={solid}>
            {text}
            {children}
          </StyledButtonA>
        ) : (
          <StyledButton as={as} to={url} solid={solid}>
            {text}
            {children}
          </StyledButton>
        )
      }
    </>
  )
}

export default Button
