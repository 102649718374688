import styled from 'styled-components'
import { Link } from "@reach/router"

export const StyledHeader = styled.header`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 9rem;
  justify-content: flex-start;
  left: 0;
  padding: 0 4rem;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99999;

  a {
    margin-left: auto;
  }

  & a {
    @media (min-width: 600px) {
      margin-left: 0;
    }
  }
`

export const LogoContainer = styled.div`
  height: 1px;
  left: 2rem;
  margin: auto;
  max-width: 110rem;
  position: fixed;
  right: 2rem;
  top: 0;
  width: 100%;

  @media (min-width: 500px) {
    left: 4rem;
    right: 4rem;
  }
`

export const LogoLink = styled(Link)`
  display: block;
  left: 0;
  opacity: 1;
  position: absolute;
  top: 0;
  transition: opacity 0.3s;
  width: 4.8rem;
  z-index: 9999;

  @media (min-width: 500px) {
    width: 5.6rem;
  }

  &:hover {
    opacity: 0.5;
  }
`

export const StyledLogo = styled.img``

export const Menu = styled.nav`
  display: none;

  @media (min-width: 600px) {
    display: flex;
    justify-content: center;
    margin-left: auto;
  }

  & ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 1rem;
  }

  & li {
    align-items: center;
    display: flex;
    margin-right: 2rem;

    @media (min-width: 768px) {
      margin-right: 4rem;
    }
  }

  & li a {
    color: white;
    font-family: 'Ubuntu';
    font-size: 1.3rem;
    font-weight: 700;
    letter-spacing: 0.08em;
    transition: color 0.4s;
    text-transform: uppercase;

    &:hover {
      color: #907E63;
    }
  }
`
