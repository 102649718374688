import React from 'react'
import { Router } from '@reach/router'
import styled from 'styled-components'
import Contact from './views/contact'
import Footer from './components/footer'
import Header from './components/header'
import Home from './views/home'
import Portfolio from './views/portfolio'
import Project from './views/project'
import ScrollTop from './components/scrolltop'

const Container = styled.div`
  padding-top: 9rem;
  position: relative;
`

const App = () => (
  <Container>
    <Header />
    <Router primary={false}>
      <ScrollTop path="/">
        <Home path="/" />
        <Contact path="contact" />
        <Portfolio path="portfolio" />
        <Project path="project/:projectId" />
      </ScrollTop>
    </Router>
    <Footer />
  </Container>
)

export default App
