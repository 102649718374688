import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import imgLogos from '../../images/service_logos.svg'
import imgPhoto from '../../images/jonathan_cooper_photo.png'
import imgLogo from '../../images/logo_large_gold.svg'

const StyledHero = styled.div`
  display: flex;
  flex-direction: column;
  overflow: visible;
  padding: 8rem 4rem 0;
  position: relative;
  width: 100%;

  @media (min-width: 768px) {
    padding: 16rem 4rem 0;
  }
`

const StyledCopy = styled.div`
  color: #FFF;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 9999;

  & > a {
    margin: 0 auto 5rem;
    max-width: 30rem;
  }

  @media (min-width: 600px) {
    text-align: center;
    margin-left: 0;
  }

  @media (min-width: 768px) {
    padding: 0 4rem;
    margin-left: auto;
    width: 100%;
  }
`

const Logo = styled.img`
    opacity: ${({ opacity }) => opacity ? opacity : 0};
    margin: auto;
    transition: all 3s;
`

const Profile = styled.div`
  background: #fff;
  border-radius: 0.3rem;
  box-shadow: 0 0.2rem 0 rgba(0,0,0,0.13);
  display: flex;
  flex-direction: column;
  margin: 8rem auto 0;
  max-width: 110rem;
  padding: 4rem;
  position: relative;
  width: 100%;

  @media (min-width: 768px) {
    align-content: center;
    align-items: center;
    flex-direction: row;
    height: 24.6rem;
    justify-content: center;
    padding: 10rem;
  }

  & > img {
    display: none;

    @media (min-width: 1024px) {
      display: block;
      max-width: 39.2rem;
      position: absolute;
      right: 2rem;
      top: -3.4rem;
    }
  }
`

const ProfileTitle = styled.h2`
  font-family: 'Bebas Neue';
  font-size: 3.7rem;
  margin-top: 0;

  &:after {
    background: #907E63;
    content: "";
    display: block;
    margin-top: 2rem;
    height: 0.3rem;
    width: 3rem;
  }
`

const ProfileBody = styled.p`
  color: #67686B;
  font-family: 'Ubuntu';
  font-size: 1.6rem;
  line-height: 2;
  padding: 0;

  @media (min-width: 768px) {
    padding: 0 0 0 8rem;
  }

  @media (min-width: 1024px) {
    padding: 0 26rem 0 8rem;
  }
`

const Logos = styled.div`
  margin: 6rem auto 0;
  max-width: 46.8rem;

  @media (min-width: 768px) {
    margin: 6rem auto;
  }
`

const Hero = () => {
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    setOpacity(1);
  }, [opacity]);

  return (
    <StyledHero>
      <StyledCopy>
        <Logo src={imgLogo} opacity={opacity} />
        <Logos>
          <img src={imgLogos} />
        </Logos>
      </StyledCopy>

      <Profile>
        <ProfileTitle>About</ProfileTitle>
        <ProfileBody>Bastion Digital offers proven expertise in JavaScript, React, React Native, GraphQL, Node.js, serverless architecture and more. We would love to be a part of your next project!</ProfileBody>
        <img src={imgPhoto} />
      </Profile>
    </StyledHero>
  )
}

export default Hero

