import { SEND_EMAIL_SERVICE_URL } from '../_constants'

export const postContactEmail = (body, callback1, callback2, initialState) => {
  const serviceUrl = SEND_EMAIL_SERVICE_URL
  const xhr = new XMLHttpRequest()
  const requestType = "POST"

  xhr.open(requestType, serviceUrl, true);
  xhr.setRequestHeader('Accept', 'application/json; charset=utf-8')
  xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')
  xhr.send(JSON.stringify(body));
  xhr.onloadend = response => {
    if (response.target.status === 200) {
      callback1('Thanks for the message. I’ll be in touch shortly.')
      callback2(initialState)
    } else {
      callback1('Something went wrong')
    }
  }
}
