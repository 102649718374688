import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import data from '../../data/projects'
import Img from 'react-image'
import Cta from '../../components/cta'

const ProjectHeader = styled.header`
  align-items: center;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5rem 0 2rem;
  position: relative;
  text-align: left;

  @media (min-width: 600px) {
    padding: 10rem 0 8rem;
  }

  & > section {
    flex-direction: column;
    max-width: 74rem;
  }

  & h1 {
    border-top: 1px solid #907E63;
    font-family: 'Bebas Neue';
    font-size: 4.8rem;
    line-height: 1;
    letter-spacing: -0.02em;
    margin: 0;
    padding: 4rem 0;
    width: 100%;

    @media (min-width: 600px) {
      font-size: 8.3rem;
      line-height: 7.8rem;
      padding: 4rem 14rem 0 0;
    }

    @media (min-width: 768px) {
      padding: 4rem 20rem 0 0;
    }
  }
`

const HeaderSubTitle = styled.p`
  border-bottom: 1px solid #907E63;
  border-top: 1px solid #907E63;
  color: #907E63;
  font-family: 'Bebas Neue';
  font-size: 2rem;
  letter-spacing: 0.1em;
  line-height: 1.1;
  letter-spacing: 0.1em;
  margin: 2.2rem 0 0;
  padding: 1.4rem 0;
  width: 100%;
`

const ProjectDetails = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 74rem;
  padding: 4rem 0 0;
  position: relative;
  width: 100%;

  @media (min-width: 500px) {
    padding: 4rem 0 0;
  }

  @media (min-width: 600px) {
    padding: 8rem 0 0;
  }
`

const DetailWrap = styled.div`
  border-top: 1px solid #fff;
  display: flex;
  flex-direction: column;
  padding-right: 0;
  width: 100%;

  @media (min-width: 500px) {
    padding-right: ${({ wide }) => wide ? '0' : '2rem'};
    width: ${({ wide }) => wide ? '66%' : '33%'};
  }
`

const DetailHeader = styled.h2`
  color: white;
  font-size: 1.2rem;
  font-weight: 700;
  margin: 0;
`

const Detail = styled.p`
  color: white;
  font-size: 1.3rem;
  font-weight: 300;
  line-height: 1.8;
  margin: 1rem 0 2rem;

  @media (min-width: 500px) {
    margin: 1rem 0 3rem;
  }
`

const ProjectScreens = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0 0 6rem 0;

  & > section {
    max-width: 88rem;
  }

  p {
    color: #666;
    border-left: 0.4rem solid #907E63;
    font-family: 'Ubuntu';
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1;
    margin: 0 0 1rem;
    padding: 0 0 0 0.6rem;
    width: 100%;
  }

  img {
    box-shadow: 0 0.2rem 2.4rem rgba(0,0,0,0.14);
    margin: 0 0 4rem 0;
  }
`

const Chevron = styled.div`
  background: rgb(37,37,37);
  background: linear-gradient(319deg, rgba(37,37,37,1) 10%, rgba(80,80,80,1) 100%);
  border-bottom: 0.5rem solid #907E63;
  height: 80rem;
  left: 0;
  position: absolute;
  right: 0;
  top: -9rem;
  width: 100%;
`

const StyledContent = styled.section`
  align-items: center;
  background: ${({ bg }) => bg ? bg : "transparent"};
  background-size: contain;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: auto;
  max-width: 96rem;
  overflow: visible;
  padding: 0 4rem;
  position: relative;
  width: 100%;

  @media (min-width: 600px) {
    font-size: 3.2rem;
  }

  @media (min-width: 1024px) {
    font-size: 3.2rem;
  }
`

const initialState = [{
  id: '',
  slug: '',
  subTitle: '',
  thumb: '',
  title: '',
  imgs: [{
    alt: '',
    img: ''
  }]
}];

const Home = ({ projectId }) => {
  const [project, setProject] = useState(initialState);
  const proj = project[0];

  useEffect(() => {
    const currentProject = data.filter(item => item.slug == projectId);
    setProject(currentProject)
  }, []);

  return (
    <>
      <ProjectHeader>
        <Chevron />
        <StyledContent>
          <h1>{proj.title}</h1>
          <HeaderSubTitle>{proj.project}</HeaderSubTitle>
        </StyledContent>
        {/* <ProjectDetails>
          <DetailWrap>
            <DetailHeader>Delivered</DetailHeader>
            <Detail>{proj.subTitle}</Detail>
            <DetailHeader>Key Technology</DetailHeader>
            <Detail>{proj.tech}</Detail>
          </DetailWrap>
          <DetailWrap wide>
            <DetailHeader>About</DetailHeader>
            <Detail>{proj.description}</Detail>
          </DetailWrap>
        </ProjectDetails> */}
      </ProjectHeader>
      <ProjectScreens>
        <StyledContent>
          {
            proj &&
            proj.imgs &&
            proj.imgs.map((p, i) => (
              <div key={p.alt + i}>
                <p>{p.alt}</p>
                <Img
                  alt={p.alt}
                  src={p.img}
                />
              </div>
            ))
          }
        </StyledContent>
      </ProjectScreens>
      <Cta />
    </>
  )
}

export default Home
