import styled from 'styled-components'

export const StyledClients = styled.section`
  background: #2B2D30;
  display: flex;
  flex-direction: column;
  padding: 4rem 0 6rem;
  width: 100%;
`

export const StyledTitle = styled.h2`
  font-size: 1.8rem;
  color: #505056;
  margin: 0 0 3rem 0;
  text-align: center;
  width: 100%;
`

export const LogoWrap = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

export const StyledLogo = styled.img`
  height: 2rem;
  width: auto;

  &:first-child {
    display: none;
  }

  &:last-child {
    display: none;
  }

  @media (min-width: 500px) {
    height: 2rem;

    &:first-child {
      height: 2.4rem;
      display: block;
    }

    &:last-child {
      height: 2.2rem;
      display: block;
    }
  }

  @media (min-width: 768px) {
    height: 3rem;
    width: auto;

    &:first-child {
      height: 3.8rem;
    }

    &:last-child {
      height: 2.4rem;
    }
  }
`
