import React from 'react'
import WorkTeaser from '../work-teaser'
import {
  ColWrapper, 
  FeatureWrapper, 
  FeaturedHeader,
  LogoWrap, 
  ProfileTitle, 
  StyledButton,
  StyledContent, 
  StyledFeatures, 
  StyledLogo,
  Title, 
} from './FeaturedStyles'
import imgLogos from '../../images/brand_logos.svg'

import imgThumb1 from '../../images/thumbs/bg-mario.jpg'
import imgThumb2 from '../../images/thumbs//bg-glacier.jpg'
import imgThumb3 from '../../images/thumbs/bg-jungle.jpg'

const Featured = () => (
  <StyledFeatures>
    <StyledContent>
      <Title>Over 15 years of experience with some of the world's most recognizable brands.</Title>
      <LogoWrap>
        <StyledLogo src={imgLogos} />
      </LogoWrap>
      <FeatureWrapper>
        <FeaturedHeader>
          <ProfileTitle>Projects</ProfileTitle>
          <StyledButton to="portfolio">View All</StyledButton>
        </FeaturedHeader>
        <ColWrapper>
          <WorkTeaser slug="outcomes-and-evidence" bg={imgThumb3} project="App Development" client="IRC" />
          <WorkTeaser slug="mario-party-island-tour" bg={imgThumb1} project="Engineering" client="Nintendo" />
          <WorkTeaser slug="expedition-travel-advisor" bg={imgThumb2} project="Design &amp; Engineering" client="ETA" />
        </ColWrapper>
      </FeatureWrapper>
    </StyledContent>
  </StyledFeatures>
)

export default Featured
