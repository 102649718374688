import React from 'react'
import Clients from '../../components/clients'
import Hero from '../../components/hero'
import Services from '../../components/services'
import Featured from '../../components/featured'
import styled from 'styled-components'
import Cta from '../../components/cta'

const Container = styled.div`
  position: relative;
`

const Chevron = styled.div`
  background: rgb(37,37,37);
  background: linear-gradient(319deg, rgba(37,37,37,1) 10%, rgba(80,80,80,1) 100%);
  border-bottom: 0.5rem solid #907E63;
  height: 80rem;
  left: 0;
  position: absolute;
  right: 0;
  top: -9rem;
  width: 100%;
`

const Home = () => (
  <Container>
    <Chevron />
    <Hero />
    <Services />
    <Featured />
    <Cta />
  </Container>
)

export default Home
