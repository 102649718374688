import styled from 'styled-components'

export const Container = styled.div`
  background: rgb(37,37,37);
  background: linear-gradient(319deg, rgba(37,37,37,1) 10%, rgba(80,80,80,1) 100%);
  border-bottom: 0.5rem solid #907E63;
  display: flex;
  margin-top: -9rem;
  min-height: 100vh;
  padding: 12rem 4rem 4rem;
  justify-content: center;
`

export const Copy = styled.div`
  color: white;
  font-family: 'Bebas Neue';
  font-size: 3.2rem;
  padding: 0;
  width: 100%;

  @media (min-width: 600px) {
    padding: 2rem 4rem 0;
  }

  @media (min-width: 768px) {
    padding: 6rem 4rem 0 0;
    width: 52%;
  }

  @media (min-width: 1024px) {
    padding: 6rem 8rem 0 0;
  }

  p {
    color: #fff;
    font-family: 'Ubuntu';
    font-size: 1.6rem;
    line-height: 2;
    opacity: 0.6;
    padding: 0;
  }
`

export const Title = styled.h1`
  color: white;
  flex: 0 0 100%;
  font-family: 'Bebas Neue';
  font-size: 2.8rem;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1;
  margin: 0 auto;
  padding: 0;
  text-align: left;

  @media (min-width: 600px) {
    font-size: 4.8rem;
  }

  &:after {
    background: #907E63;
    content: "";
    display: block;
    margin-top: 0;
    height: 0.3rem;
    width: 3rem;
  }
`

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  margin: 4rem 0;
  width: 100%;

  @media (min-width: 600px) {
    margin: 4rem 0 8rem;
    padding: 0 4rem;
  }

  @media (min-width: 768px) {
    margin: 10rem 0;
    padding: 0;
    width: 48%;
  }

  & label,
  & input,
  & textarea {
    display: block;
    width: 100%;
  }

  & input,
  & textarea {
    border: 0.1rem solid #D3D3D3;
    border-radius: 0.2rem;
    font-size: 1.6rem;
    margin: 0.8rem 0 0;
  }

  & label {
    color: rgba(255,255,255,0.6);
    font-family: 'Ubuntu';
    font-size: 1.3rem;
    margin: 0 0 2rem;

    @media (min-width: 768px) {
      font-size: 1.5rem;
      margin: 0 0 3rem;
    }
  }

  & input,
  & textarea {
    height: 5rem;
    outline: none;
    padding: 0 1.6rem;
    transition: border-color 0.3s;

    &:hover {
      border: 0.3rem solid #AAA;
    }

    &:focus {
      border: 0.3rem solid #907E63;
    }
  }

  & textarea {
    height: 10rem;
    padding: 1.6rem;
  }
`

export const Message = styled.p`
  color: #0ec374;
  font-size: 1.8rem;
`
