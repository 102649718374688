import React from 'react'
import Button from '../button'
import Content from '../content'
import styled from 'styled-components'

const StyledCta = styled.section`
  background: transparent;
  padding: 0 4rem 8rem 4rem;
  text-align: center;
  width: 100%;

  @media (min-width: 768px) {
    padding: 0 4rem 12rem 4rem;
  }

  a {
    border: 0.2rem solid transparent;
    color: #fff;
    background: #907E63;
    border-radius: 0.5rem;
    margin: 1rem auto 0;

    &:hover {
      border: 0.2rem solid #907E63;
      color: #907E63;
    }
  }
`

const Title = styled.h2`
  color: #353539;
  font-family: 'Bebas Neue';
  font-size: 2.8rem;
  line-height: 1.6;
  margin-top: 0;
  width: 100%;

  @media (min-width: 600px) {
    font-size: 4.3rem;
  }
`

const Cta = () => (
  <StyledCta>
    <Content>
      <Title>Let's build something together</Title>
      <Button url="mailto:bastiondigitalllc@gmail.com" as="a">Get In Touch</Button>
    </Content>
  </StyledCta>
)

export default Cta
