import React from 'react'
import { Link } from "@reach/router"
import { StyledCta, Col, Menu, Logo, CopyRight, LogoWrap } from './FooterStyles'
import Content from '../content'
import imgLogo from '../../images/logo_large_gold.svg'

const currentYear = new Date().getFullYear();

const Footer = () => (
  <StyledCta>
    <Content>
      <Col>
        <Menu>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="portfolio">Portfolio</Link>
          </li>
          <li>
            {/* <Link to="contact">Contact</Link> */}
            <a href="mailto:bastiondigitalllc@gmail.com" title="contact">Contact</a>
          </li>
        </Menu>
        <CopyRight>© {currentYear} Bastion Digital</CopyRight>
      </Col>
      <Col>
        <LogoWrap>
          <Logo src={imgLogo} />
        </LogoWrap>
      </Col>
    </Content>
  </StyledCta>
)

export default Footer
