import React from 'react'
import Content from '../content'
import imgLogo1 from '../../images/logo_walmart.png'
import imgLogo2 from '../../images/logo_mtv.png'
import imgLogo3 from '../../images/logo_nintendo.png'
import imgLogo4 from '../../images/logo_visa.png'
import imgLogo5 from '../../images/logo_starbucks.png'
import { StyledClients, StyledTitle, LogoWrap, StyledLogo } from './ClientStyles'

const Clients = () => (
  <StyledClients>
    <Content>
      <StyledTitle>Client Experience</StyledTitle>
    </Content>
    <Content>
      <LogoWrap>
        <StyledLogo src={imgLogo5} />
        <StyledLogo src={imgLogo1} />
        <StyledLogo src={imgLogo3} />
        <StyledLogo src={imgLogo2} />
        <StyledLogo src={imgLogo4} />
      </LogoWrap>
    </Content>
  </StyledClients>
)

export default Clients
