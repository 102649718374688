import React from 'react'
import styled from 'styled-components'

const StyledContent = styled.section`
  align-items: center;
  background: ${({ bg }) => bg ? bg : "transparent"};
  background-size: contain;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: auto;
  max-width: 110rem;
  overflow: visible;
  position: relative;
  width: 100%;

  @media (min-width: 600px) {
    font-size: 2.7rem;
  }
`

const Content = ({ children, bg }) => (
  <StyledContent bg={bg}>
    {children}
  </StyledContent>
)

export default Content
