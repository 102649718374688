import React from 'react'
import imgIcon1 from '../../images/icon_aim.svg'
import imgIcon2 from '../../images/icon_speed.svg'
import imgIcon3 from '../../images/icon_phone.svg'
import { BG, StyledServices, StyledContent, Col, Title, Grid } from './ServicesStyles'

const Services = () => (
  <StyledServices>
    <StyledContent>
      <Title>Whether you're starting a new project or scaling an existing one, Bastion Digital is here to help.</Title>
      <Grid>
        <Col>
          <img src={imgIcon1} />
          <h3>Design</h3>
          <ul>
            <li>Wireframing & Planning</li>
            <li>Website & App Design</li>
            <li>UX Consultation</li>
            <li>Deisgn Systems</li>
            <li>Style Guides</li>
          </ul>
        </Col>
        <Col>
          <img src={imgIcon2} />
          <h3>Engineering</h3>
          <ul>
            <li>Project Management</li>
            <li>Software Engineering</li>
            <li>Web & Native Development</li>
            <li>Performance Optimization</li>
            <li>Accessibility Consultation</li>
          </ul>
        </Col>
        <Col>
          <img src={imgIcon3} />
          <h3>Architecture</h3>
          <ul>
            <li>Architecture Planning</li>
            <li>Performance Tuning</li>
            <li>Product Sustanability</li>
            <li>Maintenance</li>
            <li>Platform Development</li>
          </ul>
        </Col>
      </Grid>
    </StyledContent>
    <BG />
  </StyledServices>
)

export default Services
