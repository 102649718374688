import styled from 'styled-components'

export const StyledCta = styled.section`
  background: #252629;
  display: flex;
  flex-direction: row;
  padding: 6rem 4rem;
  position: relative;
  width: 100%;
`

export const Col = styled.div`
  color: #717174;
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
`

export const Menu = styled.ul`
  margin: 0;

  & li {
    color: #717174;
    font-family: 'Ubuntu';
    font-size: 1.3rem;
    font-weight: 700;
    letter-spacing: 0.05em;
    line-height: 3.6rem;
    text-transform: uppercase;
  }

  & li a {
    color: #717174;
    transition: color 0.4s;

    &:hover {
      color: #907E63;
    }
  }
`

export const Logo = styled.img`
  align-self: flex-start;
  display: none;

  @media (min-width: 600px) {
    display: block;
  }
`

export const CopyRight = styled.p`
  font-family: 'Ubuntu';
  margin-top: 6rem;
`

export const LogoWrap = styled.div`
  max-width: 15rem;
`
