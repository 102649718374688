import styled from 'styled-components'
import Content from '../content'

export const StyledServices = styled.section`
  display: flex;
  flex-direction: row;
  padding: 6rem 4rem;
  position: relative;
  width: 100%;

  @media (min-width: 700px) {
    padding: 12rem 4rem;
  }
`

export const StyledContent = styled(Content)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  width: 100%;
`

export const Title = styled.h2`
  flex: 0 0 100%;
  font-family: 'Bebas Neue';
  font-size: 2.8rem;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1.6;
  margin: 0 auto 8rem;
  padding: 0 14%;
  text-align: center;
  @media (min-width: 600px) {
    font-size: 4.3rem;
    line-height: 5.4rem;
  }
  @media (min-width: 768px) {
    font-size: 4.3rem;
    line-height: 5.4rem;
    margin: 0 auto 12rem;
  }
`

export const Grid = styled.div`
  display: grid;
  margin: 0 auto;
  gap: 3.5rem;
  grid-template-columns: 1fr;
  width: 100%;

  @media (min-width: 768px) {
    gap: 2rem;
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export const Col = styled.div`
  background: #fff;
  border-radius: 0.3rem;
  border: none;
  box-shadow: 0 0.2rem 0 rgba(0,0,0,0.13);
  margin: 0 auto;
  max-width: 37.5rem;;
  padding: 4rem;
  text-align: center;
  width: 100%;

  @media (min-width: 768px) {
    border: none;
    padding: 2rem;
  }

  @media (min-width: 1024px) {
    padding: 4rem;
  }

  img {
    position: relative;
    top: -6rem;

    @media (min-width: 1024px) {
      top: -8rem;
    }
  }


  h3 {
    font-family: 'Bebas Neue';
    font-size: 3.2rem;
    margin: -5rem 0 3rem 0;
  }

  li {
    color: #717174;
    font-family: 'Ubuntu';
    font-size: 1.3rem;
    letter-spacing: -0.01em;
    line-height: 1.3rem;
    margin: 1.3rem 0;
    text-align: left;

    @media (min-width: 1024px) {
      font-size: 1.6rem;
      letter-spacing: -0.01em;
      line-height: 1.6rem;
      margin: 1.7rem 0;
    }

    &:before {
      color: #907E63;
      content: '•';
      font-size: 1.6rem;
      font-weight: 700;
      margin-right: 0.8rem;
    }
  }
`

export const BG = styled.div`
  background: rgb(37,37,37);
  background: linear-gradient(319deg, rgba(37,37,37,1) 10%, rgba(80,80,80,1) 100%);
  border-bottom: 0.5rem solid #907E63;
  border-top: 0.5rem solid #907E63;
  height: 160rem;
  left: 0;
  position: absolute;
  right: 0;
  top: 50rem;
  width: 100%;
  z-index: -1;

  @media (min-width: 768px) {
    height: 80rem;
  }
`
