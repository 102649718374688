import React, { useState } from 'react'
import Button from '../../components/button'
import Content from '../../components/content'
import Honeypot from '../../components/honeypot'
import { postContactEmail } from '../../api/email'
import { Container, Copy, Title, StyledForm, Message } from './ContactStyles'


const Contact = () => {
  const initialState = { name: '', reply_to: '', message: '', namexyz: '' }
  const [values, setValues] = useState({ name: '', reply_to: '', message: '', namexyz: '' })
  const [request, setRequest] = useState('')

  const handleInputChange = e => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (values.name.length !== 0) return
    const finalValues = {
      name: values.namexyz,
      reply_to: values.reply_to,
      message: values.message
    }
    postContactEmail(finalValues, setRequest, setValues, initialState)
  }

  return (
    <Container>
      <Content>
        <Copy>
          <Title>Interested in working together?</Title>
          <p>If you are interested in any of my services please feel free to reach out. I am happy to answer any questions you might have regarding estimates, availability, and services. Let’s start building something amazing together.</p>
        </Copy>
        <StyledForm
          onSubmit={e => handleSubmit(e)}
          action="{{ SERVICE URL }}"
          method="POST"
        >
          <Honeypot
            handleChange={handleInputChange}
            val={values.name}
          />
          <label>
            Name
            <input
              name="namexyz"
              onChange={handleInputChange}
              required
              type="text"
              value={values.namexyz}
            />
          </label>
          <label>
            Email
            <input
              name="reply_to"
              onChange={handleInputChange}
              required
              type="email"
              value={values.reply_to}
            />
          </label>
          <label>
            Message:
            <textarea
              name="message"
              onChange={handleInputChange}
              required
              value={values.message}
            ></textarea>
          </label>
          <Button as="button" type="submit" text="Send Message" />
          {request && request.length && (
            <Message>{request}</Message>
          )}
        </StyledForm>
      </Content>
    </Container>
  )
}

export default Contact
