import React from 'react'
import Cta from '../../components/cta'
import WorkTeaser from '../../components/work-teaser'
import data from '../../data/thumbs'
import styled from 'styled-components'

export const Work = styled.section`
  background: rgb(37,37,37);
  background: linear-gradient(319deg, rgba(37,37,37,1) 10%, rgba(80,80,80,1) 100%);
  border-bottom: 0.5rem solid #907E63;
  display: flex;
  flex-direction: row;
  margin-top: -9rem;
  padding: 17rem 0 10rem;
  position: relative;
  width: 100%;

  @media (min-width: 600px) {
    padding: 17rem 0 10rem;
  }

  & section > a {
    background-size: 101% auto;
    background-position: center;
    margin: 2rem auto 0;
    width: 100%;

    &:nth-child(2) {
      background-position: center 63%;
    }
    
    &:nth-child(3) {
      background-position: center 28%;
    }
  }

`

export const Work2 = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10rem 0;
  position: relative;
  width: 100%;

  & section > a {
    margin: 2rem auto 0;
  }
`

export const Title = styled.h2`
  color: white;
  flex: 0 0 100%;
  font-family: 'Bebas Neue';
  font-size: 2.8rem;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1.6;
  margin: 0 auto;
  padding: 0;
  text-align: left;

  @media (min-width: 600px) {
    font-size: 4.3rem;
  }

  &:after {
    background: #907E63;
    content: "";
    display: block;
    margin-top: 0;
    height: 0.3rem;
    width: 3rem;
  }
`

export const Title2 = styled.h2`
  color: rgb(53, 53, 57);
  flex: 0 0 100%;
  font-family: 'Bebas Neue';
  font-size: 2.8rem;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1.6;
  margin: 0 auto;
  padding: 0;
  text-align: left;

  @media (min-width: 600px) {
    font-size: 4.3rem;
  }

  &:after {
    background: #907E63;
    content: "";
    display: block;
    margin-top: 0;
    height: 0.3rem;
    width: 3rem;
  }
`

const StyledContent = styled.section`
  align-items: center;
  background: ${({ bg }) => bg ? bg : "transparent"};
  background-size: contain;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: auto;
  max-width: 96rem;
  overflow: visible;
  padding: 0 4rem;
  position: relative;
  width: 100%;

  @media (min-width: 600px) {
    font-size: 3.2rem;
  }

  @media (min-width: 1024px) {
    font-size: 3.2rem;
  }
`

const TeaserGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  margin-top: 2rem;
  width: 100%;

  @media (min-width: 600px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  & > a {
    margin: 0;
    padding-bottom: 90%;
    width: 100%;
  }
`

const Portfolio = () => {
  const featured = data.slice(0, 3);
  const workNotFeatured = data.slice(3, data.length);

  return (
    <>
      <Work>
        <StyledContent>
          <Title>Featured Projects</Title>
          {featured.map(({
            img,
            title,
            slug,
            subTitle
          }) => (
              <WorkTeaser
                key={slug}
                bg={img}
                client={title}
                project={subTitle}
                slug={slug}
              />
            ))}
        </StyledContent>
      </Work>
      <Work2>
        <StyledContent>
          <Title2>Work History</Title2>
          <TeaserGrid>
            {workNotFeatured.map(({
              img,
              title,
              slug,
              subTitle
            }) => (
                <WorkTeaser
                  key={slug}
                  bg={img}
                  client={title}
                  project={subTitle}
                  slug={slug}
                />
              ))}
            </TeaserGrid>
        </StyledContent>
      </Work2>
      <Cta />
    </>
  )
}

export default Portfolio
