import React from 'react'
import { Link } from "@reach/router"
import { StyledHeader, LogoContainer, LogoLink, StyledLogo, Menu } from './HeaderStyles'
import Button from '../button'
import Content from '../content'
import imgLogo from '../../images/logo_banner.svg'

const Header = () => (
  <StyledHeader>
    <Content>
      <LogoContainer>
        <LogoLink to="/">
          <StyledLogo src={imgLogo} alt="Logo for Bastion Digital" />
        </LogoLink>
      </LogoContainer>
      <Menu>
        <ul>
          <li>
            <Link to="portfolio">Portfolio</Link>
          </li>
        </ul>
      </Menu>
      <Button url="mailto:bastiondigitalllc@gmail.com" text="Contact" solid="no" as="a" />
    </Content>
  </StyledHeader>
)

export default Header
