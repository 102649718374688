import styled from 'styled-components'
import { Link } from '@reach/router'
import Content from '../content'

export const StyledFeatures = styled.section`
  display: flex;
  flex-direction: row;
  padding: 0 4rem 4rem;
  position: relative;
  width: 100%;

  @media (min-width: 600px) {
    padding: 0 4rem 8rem;
  }

  @media (min-width: 768px) {
    padding: 0 4rem 11rem;
  }
`

export const StyledContent = styled(Content)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  width: 100%;
`

export const Title = styled.h2`
  color: white;
  flex: 0 0 100%;
  font-family: 'Bebas Neue';
  font-size: 2.8rem;
  font-weight: 700;
  line-height: 1.6;
  margin: 0 auto 4rem;
  padding: 0;
  text-align: center;

  @media (min-width: 600px) {
    font-size: 4.3rem;
    line-height: 5.4rem;
    padding: 0 15%;
  }
`

export const ColWrapper = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @media (min-width: 600px) {
    flex-direction: row;
  }
`

export const Col = styled.div`
  box-shadow: 0 2rem 0 rgba(0,0,0,1);
  background-image: ${({ bg }) => bg ? `url(${bg})` : "transparent"};
  background-size: cover;
  border-radius: 0.2rem;
  border: 1rem solid #F2F5F7;
  height: 0;
  margin-bottom: 2rem;
  padding-bottom: 100%;
  position: relative;
  text-align: center;
  transition: border-color 0.5s;
  width: 100%;

  @media (min-width: 768px) {
    margin-bottom: 0;
    padding-bottom: 32%;
    width: 32%;
  }

  &:before {
    align-items: center;
    bottom: 10%;
    color: #0098FF;
    content: ${({ project }) => project ? `"${project}"` : ""};
    display: flex;
    font-size: 1.3rem;
    font-weight: 500;
    justify-content: center;
    left: 10%;
    opacity: 0;
    position: absolute;
    transition: opacity 0.5s;
    z-index: 10;
  }

  &:after {
    align-items: center;
    background: rgba(0,0,0,0.8);
    bottom: 0;
    color: white;
    content: ${({ client }) => client ? `"${client}"` : ""};
    cursor: pointer;
    display: flex;
    font-size: 2.4rem;
    font-weight: 700;
    justify-content: center;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    text-transform: uppercase;
    top: 0;
    transition: opacity 0.5s;
    z-index: 5;
  }

  &:hover {
    border: 1rem solid #0098FF;

    &:after,
    &:before {
      opacity: 1;
    }
  }
`

export const LogoWrap = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 6rem;
  width: 100%;

  @media (min-width: 600px) {
    padding: 0 3rem;
  }

  @media (min-width: 700px) {
    margin-bottom: 10rem;
  }

  @media (min-width: 768px) {
    padding: 0 6rem;
  }
`

export const StyledLogo = styled.img`
  height: 2rem;
  margin: auto;
  width: auto;

  @media (min-width: 500px) {
    height: 2rem;
  }

  @media (min-width: 768px) {
    height: 3rem;
    width: auto;
  }
`

export const ProfileTitle = styled.h2`
  font-family: 'Bebas Neue';
  font-size: 3.2rem;
  margin: 0 0 4rem 0;

  &:after {
    background: #907E63;
    content: "";
    display: block;
    height: 0.3rem;
    margin-top: 2rem;
    width: 3rem;
  }
`

export const FeatureWrapper = styled.div`
  background-image: ${({ bg }) => bg ? `url(${bg})` : "transparent"};
  background-size: cover;
  background: #fff;
  border-radius: 0.2rem;
  box-shadow: 0 0.2rem 0 rgba(0,0,0,0.13);
  display: flex;
  flex-direction: column;
  padding: 3rem;
  transition: border-color 0.5s;
  width: 100%;

  @media (min-width: 768px) {
    padding: 6rem;
  }
`

export const FeaturedHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  & > a {
    margin-left: auto;
  }
`

export const StyledButton = styled(Link)`
  background: #907E63;
  border: 0.2rem solid #907E63;
  border-radius: 0.2rem;
  color: #fff;
  display: inline-flex;
  font-family: 'Ubuntu';
  font-size: 1.3rem;
  font-weight: 700;
  height: 5rem;
  justify-content: center;
  letter-spacing: 0.1rem;
  line-height: 4.6rem;
  padding: 0 1rem;
  text-align: center;
  text-transform: uppercase;
  transition: background 0.4s, color 0.4s;

  @media (min-width: 500px) {
    padding: 0 4rem;
  }

  &:hover {
    background: transparent;
    color: #907E63;
    cursor: pointer;
  }
`