import React from 'react'
import styled from 'styled-components'

const StyledHoneypot = styled.div`
  height: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 0;
  z-index: -1;
`

const Honeypot = ({ handleChange, val = "" }) => (
  <StyledHoneypot>
    <label htmlFor="name" />
    <input
      autoComplete="off"
      id="name"
      name="name"
      onChange={handleChange}
      placeholder="Your name here"
      tabIndex="-1"
      type="text"
      value={val}
    />
  </StyledHoneypot>
)

export default Honeypot
