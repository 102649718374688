import imgBrv1 from '../images/projects/work-bravely-default-ar.png'
import imgBrv2 from '../images/projects/work-bravely-default-characters.png'
import imgBrv3 from '../images/projects/work-bravely-default-gameplay.png'
import imgBrv4 from '../images/projects/work-bravely-default-home.png'
import imgBrv5 from '../images/projects/work-bravely-default-jobs.png'
import imgBrv6 from '../images/projects/work-bravely-default-quiz.png'
import imgBrv7 from '../images/projects/work-bravely-default-video.png'

import imgCut1 from '../images/projects/work-cutty-home.png'
import imgCut2 from '../images/projects/work-cutty-mobile.jpg'
import imgCut3 from '../images/projects/work-cutty-whisky.jpg'

import imgEta1 from '../images/projects/work-eta-home.jpg'
import imgEta2 from '../images/projects/work-eta-signup.jpg'
import imgEta3 from '../images/projects/work-eta-trip.jpg'
import imgEta4 from '../images/projects/work-eta-trunk.png'
import imgEta5 from '../images/projects/work-eta-ui.png'

import imgEvo1 from '../images/projects/work-evocalize-cart.png'
import imgEvo2 from '../images/projects/work-evocalize-checkout.png'
import imgEvo3 from '../images/projects/work-evocalize-dashboard.png'
import imgEvo4 from '../images/projects/work-evocalize-happypath.png'
import imgEvo5 from '../images/projects/work-evocalize-home.png'
import imgEvo6 from '../images/projects/work-evocalize-listing.png'
import imgEvo7 from '../images/projects/work-evocalize-listings.png'
import imgEvo8 from '../images/projects/work-evocalize-style1.png'
import imgEvo9 from '../images/projects/work-evocalize-style2.png'
import imgEvo10 from '../images/projects/work-evocalize-style3.png'
import imgEvo11 from '../images/projects/work-evocalize-style4.png'
import imgEvo12 from '../images/projects/work-evocalize-style5.png'
import imgEvo13 from '../images/projects/work-evocalize-wire1.png'
import imgEvo14 from '../images/projects/work-evocalize-wire2.png'

import imgHis1 from '../images/projects/work-history-screens-small.png'
import imgHis2 from '../images/projects/work-history-screens.png'

import imgHvc1 from '../images/projects/work-hvac-assignment.jpg'
import imgHvc2 from '../images/projects/work-hvac-briefcase.jpg'
import imgHvc3 from '../images/projects/work-hvac-congrats.jpg'
import imgHvc4 from '../images/projects/work-hvac-dashboard-panel.jpg'
import imgHvc5 from '../images/projects/work-hvac-dashboard.jpg'
import imgHvc6 from '../images/projects/work-hvac-profile.jpg'
import imgHvc7 from '../images/projects/work-hvac-rankings.jpg'
import imgHvc8 from '../images/projects/work-hvac-sign-in.jpg'
import imgHvc9 from '../images/projects/work-hvac-start.jpg'
import imgHvc10 from '../images/projects/work-hvac-video.jpg'
import imgHvc11 from '../images/projects/work-hvc-sign-in.jpg'

import imgIrc1 from '../images/projects/work-irc-home.png'
import imgIrc2 from '../images/projects/work-irc-mobile.png'
import imgIrc3 from '../images/projects/work-irc-outcomes.png'
import imgIrc4 from '../images/projects/work-irc-tool1.png'
import imgIrc5 from '../images/projects/work-irc-tool2.png'
import imgIrc6 from '../images/projects/work-irc-tool3.png'

import imgMtv1 from '../images/projects/work-iysl-about.png'
import imgMtv2 from '../images/projects/work-iysl-article.png'
import imgMtv3 from '../images/projects/work-iysl-faqs.png'
import imgMtv4 from '../images/projects/work-iysl-filtered.png'
import imgMtv5 from '../images/projects/work-iysl-home.png'

import imgMar1 from '../images/projects/work-mario-party-characters.png'
import imgMar2 from '../images/projects/work-mario-party-home.png'
import imgMar3 from '../images/projects/work-mario-party-modes.png'
import imgMar4 from '../images/projects/work-mario-party-multiplayer.png'
import imgMar5 from '../images/projects/work-mario-party-window.png'

import imgMta1 from '../images/projects/work-mta-mobile-screens.png'
import imgMta2 from '../images/projects/work-mta-mobile.png'

import imgNis1 from '../images/projects/work-nissan-home.jpg'
import imgNis2 from '../images/projects/work-nissan-mobile.png'
import imgNis3 from '../images/projects/work-nissan-mw.png'

import imgOgl1 from '../images/projects/work-ogilvyone-about.png'
import imgOgl2 from '../images/projects/work-ogilvyone-awards.png'
import imgOgl3 from '../images/projects/work-ogilvyone-home.png'
import imgOgl4 from '../images/projects/work-ogilvyone-timeline.png'

import imgOss1 from '../images/projects/work-oss-lander.png'
import imgOss2 from '../images/projects/work-oss-style-guide.png'
import imgOss3 from '../images/projects/work-oss-webpack-dashboard.jpg'

import imgTfc1 from '../images/projects/work-tfc-about.jpg'
import imgTfc2 from '../images/projects/work-tfc-blog.jpg'
import imgTfc3 from '../images/projects/work-tfc-building.jpg'
import imgTfc4 from '../images/projects/work-tfc-home.jpg'
import imgTfc5 from '../images/projects/work-tfc-saved.jpg'
import imgTfc6 from '../images/projects/work-tfc-search.jpg'

import imgWds1 from '../images/projects/work-woods-articles.jpg'
import imgWds2 from '../images/projects/work-woods-content.jpg'
import imgWds3 from '../images/projects/work-woods-home.jpg'
import imgWds4 from '../images/projects/work-woods-mobile.png'

import imgME1 from '../images/projects/work-me-update-search.png'
import imgME2 from '../images/projects/work-me-update-home.png'

import imgME3 from '../images/projects/work-me-orthopedics-search.png'
import imgME4 from '../images/projects/work-me-orthopedics-profile.png'
import imgME5 from '../images/projects/work-me-orthopedics-landing.jpg'
import imgME6 from '../images/projects/work-me-orthopedics-home.jpg'

import imgME7 from '../images/projects/work-me-covid19-vaccines.jpg'
import imgME8 from '../images/projects/work-me-covid19-vaccine.jpg'
import imgME9 from '../images/projects/work-me-covid19-scheduling.jpg'
import imgME10 from '../images/projects/work-me-covid19-home.jpg'
import imgME11 from '../images/projects/work-me-covid19-home-sml.png'

import imgME12 from '../images/projects/work-me-cancer-history.jpg'
import imgME13 from '../images/projects/work-me-cancer-fad.jpg'
import imgME14 from '../images/projects/work-me-cancer-fad-results.jpg'
import imgME15 from '../images/projects/work-me-cancer-fad-profile.jpg'
import imgME16 from '../images/projects/work-me-cancer-home.jpg'

import imgME17 from '../images/projects/work-me-advanced-care-types.jpg'
import imgME18 from '../images/projects/work-me-advanced-care-center.jpg'

import thumbBrave from '../images/thumbs/bg-bravelydefault.jpg'
import thumbCutty from '../images/thumbs/bg-cutty.png'
import thumbEvocalize from '../images/thumbs/bg-evocalize.png'
import thumbGlacier from '../images/thumbs/bg-glacier.jpg'
import thumbHVC from '../images/thumbs/bg-hvc.png'
import thumbHistory from '../images/thumbs/bg-history.png'
import thumbIRC from '../images/thumbs/bg-jungle.jpg'
import thumbIysl from '../images/thumbs/bg-condom.jpg'
import thumbMTA from '../images/thumbs/bg-mta.png'
import thumbMario from '../images/thumbs/bg-mario.jpg'
import thumbNissan from '../images/thumbs/bg-nissan.png'
import thumbOgilvy from '../images/thumbs/bg-ogilvyone.png'
import thumbSpace from '../images/thumbs/bg-space.png'
import thumbTfc from '../images/thumbs/bg-tfc.png'
import thumbWoods from '../images/thumbs/bg-woods.jpg'
import thumbOrtho from '../images/thumbs/bg-me-orthopedics.jpg'
import thumbCovid19 from '../images/thumbs/bg-me-covid19.jpg'
import thumbCancer from '../images/thumbs/bg-me-cancer.jpg'

const data = [
  {
    id: 'irc',
    thumb: thumbIRC,
    title: 'International Rescue Foundation',
    subTitle: 'Web App',
    project: 'Outcomes & Evidence Framework',
    slug: 'outcomes-and-evidence',
    description: 'The interactive Outcomes and Evidence Framework supports humanitarian and development professionals to design effective programs. It delivers key information on outcomes related to health, safety, education, economic wellbeing, and power through theories of change.',
    tech: 'React, Redux, Ruby on Rails',
    imgs: [
      {
        img: imgIrc1,
        alt: 'Home'
      },
      {
        img: imgIrc3,
        alt: 'Outcomes'
      },
      {
        img: imgIrc4,
        alt: 'Tree View'
      },
      {
        img: imgIrc5,
        alt: 'Tree View'
      },
      {
        img: imgIrc6,
        alt: 'Tree View'
      },
      {
        img: imgIrc2,
        alt: 'Mobile'
      }
    ]
  },
  {
    id: 'brv',
    thumb: thumbBrave,
    title: 'Nintendo',
    subTitle: 'Website',
    project: 'Bravely Default',
    slug: 'bravely-default',
    description: 'Bravely Default is a Japanese RPG built by Square- Enix for the Nintendo 3DS.The site features rich illustrations and graphics keeping true to the Final Fantasy series.',
    tech: 'HTML, CSS3, JavaScript',
    imgs: [
      {
        img: imgBrv4,
        alt: 'Home'
      },
      {
        img: imgBrv2,
        alt: 'Characters'
      },
      {
        img: imgBrv3,
        alt: 'Gameplay'
      },
      {
        img: imgBrv5,
        alt: 'Jobs'
      },
      {
        img: imgBrv1,
        alt: 'AR Cards'
      },
      {
        img: imgBrv6,
        alt: 'Quiz'
      },
      {
        img: imgBrv7,
        alt: 'Media'
      },
    ]
  },
  {
    id: 'his',
    img: thumbHistory,
    title: 'History',
    subTitle: 'Native App',
    project: 'Video App',
    slug: 'history-channel',
    description: 'The Official History Channel Video Application was built specifically for European markets with content availability based on geolocation.',
    tech: 'Angular, Ionic, Ruby on Rails',
    imgs: [
      {
        img: imgHis2,
        alt: 'Phone View'
      },
      {
        img: imgHis1,
        alt: 'Screens'
      }
    ]
  },
  {
    id: 'mtv',
    img: thumbIysl,
    title: "MTV",
    subTitle: 'Web App',
    project: 'It\'s Your Sex Life',
    slug: 'its-your-sex-life',
    description: 'It\'s Your Sex Life is MTV\'s Emmy and Peabody Award-winning public information campaign to support young people in making responsible decisions about their sexual health.',
    tech: 'JavaScript, Angular, Ruby on Rails',
    imgs: [
      {
        img: imgMtv5,
        alt: 'Home'
      },
      {
        img: imgMtv4,
        alt: 'Filters'
      },
      {
        img: imgMtv2,
        alt: 'Article'
      },
      {
        img: imgMtv3,
        alt: 'Article'
      },
      {
        img: imgMtv1,
        alt: 'About'
      },
    ]
  },
  {
    id: 'wds',
    img: thumbWoods,
    title: 'Woods Baggot',
    subTitle: 'Website',
    project: 'SuperSpace',
    slug: 'woods-baggot',
    description: 'SuperSpace employs special algorithms and extensive data about how people use building space to inform architectural design decisions. The microsite communicates the intricacies of SuperSpace with bold and surprising design elements coupled with straightforward, linear storytelling.',
    tech: 'HTML, CSS3, JavaScript',
    imgs: [
      {
        img: imgWds3,
        alt: 'Home'
      },
      {
        img: imgWds2,
        alt: 'SuperSpace'
      },
      {
        img: imgWds1,
        alt: 'People Architecture'
      },
      {
        img: imgWds4,
        alt: 'Mobile Screens'
      }
    ]
  },
  {
    id: 'hvc',
    img: thumbHVC,
    title: 'Credit Suisse',
    subTitle: 'Web App',
    project: 'Holt Valuation Challenge',
    slug: 'credit-suisse',
    description: 'The HOLT Valuation Challenge is an online competition application that familiarizes users with the Credit Suisse HOLT methodology.',
    tech: 'JavaScript, React, Redux',
    imgs: [
      {
        img: imgHvc8,
        alt: 'Sign In'
      },
      {
        img: imgHvc9,
        alt: 'Dashboard'
      },
      {
        img: imgHvc1,
        alt: 'Video Quiz'
      },
      {
        img: imgHvc5,
        alt: 'Dashboard Unlocked'
      },
      {
        img: imgHvc4,
        alt: 'Dashboard Stage 2'
      },
      {
        img: imgHvc6,
        alt: 'Profile'
      },
      {
        img: imgHvc2,
        alt: 'Briefcase'
      },
      {
        img: imgHvc7,
        alt: 'Rankings'
      },
      {
        img: imgHvc3,
        alt: 'Success'
      }
    ]
  },
  {
    id: 'niss',
    img: thumbNissan,
    title: 'Nissan',
    subTitle: 'Web App',
    project: 'Weekend Wonders',
    slug: 'nissan',
    description: 'I was tasked by Guardian Labs—the branded content team at The Guardian—to develop new interactive approaches to creating deeper connections with readers - especially the vast majority who were accessing TheGuardian.com from their mobile device.',
    tech: 'Webpack, React, Redux',
    imgs: [
      {
        img: imgNis1,
        alt: 'Home'
      },
      {
        img: imgNis3,
        alt: 'Destinations'
      },
      {
        img: imgNis2,
        alt: 'Mobile Screens'
      }
    ]
  },
  {
    id: 'mar',
    img: thumbMario,
    title: 'Nintendo',
    subTitle: 'Website',
    project: 'Mario Party Island Tour',
    slug: 'mario-party-island-tour',
    description: 'The Mario Party Island Tour website is the official marketing site for the Nintendo 3DS based game system.',
    tech: 'HTML, CSS, JavaScrit',
    imgs: [
      {
        img: imgMar2,
        alt: 'Home'
      },
      {
        img: imgMar3,
        alt: 'Game Modes'
      },
      {
        img: imgMar1,
        alt: 'Characters'
      },
      {
        img: imgMar4,
        alt: 'Multiplayer'
      },
      {
        img: imgMar5,
        alt: 'Easter Egg Wallpapers'
      },
    ]
  },
  {
    id: 'tfc',
    img: thumbTfc,
    title: 'TFC',
    subTitle: 'Web App',
    project: 'Real Estate App',
    slug: 'tfcornerstone',
    description: 'TFC is a New York-based real estate company. TFC.com is the company\'s corporate marketing site and provides up to date information on rental availability.',
    tech: 'Drupal, jQuery, SCSS',
    imgs: [
      {
        img: imgTfc1,
        alt: 'About'
      },
      {
        img: imgTfc4,
        alt: 'Residential'
      },
      {
        img: imgTfc6,
        alt: 'Search Listings'
      },
      {
        img: imgTfc3,
        alt: 'Residence'
      },
      {
        img: imgTfc2,
        alt: 'Journal'
      },
      {
        img: imgTfc5,
        alt: 'Saved Listings'
      },
    ]
  },
  {
    id: 'cut',
    img: thumbCutty,
    title: 'Cutty Sark',
    subTitle: 'Website',
    project: 'Official US Website',
    slug: 'cutty-sark',
    description: 'Cutty Sark is a Scotch Whisky that has existed since 1923 and survived the prohibition. We were responsible for building the primary marketing site for the US market.',
    tech: 'JavaScript, jQuery, CakePHP',
    imgs: [
      {
        img: imgCut1,
        alt: 'Home'
      },
      {
        img: imgCut3,
        alt: 'The Whisky'
      },
      {
        img: imgCut2,
        alt: 'Mobile Screens'
      },
    ]
  },
  {
    id: 'mta',
    img: thumbMTA,
    title: 'MTA',
    subTitle: 'Native App',
    project: 'Train Time App',
    slug: 'mta',
    description: 'We were tasked with building the official transportation application for the MTA, Train Times. The native application makes finding up-to-the-minute departure and arrival times, service alerts, and fare information easy.',
    tech: 'JavaScript, PhoneGap, Ruby on Rails',
    imgs: [
      {
        img: imgMta2,
        alt: 'Mobile Screens'
      },
      {
        img: imgMta1,
        alt: 'Additional Views'
      },
    ]
  },
  {
    id: 'ogl',
    img: thumbOgilvy,
    title: 'Ogilvy',
    subTitle: 'Website',
    project: 'OgilvyOne Website',
    slug: 'ogilvy-one',
    description: 'Ogilvy is an award-winning integrated creative network that makes brands matter, specializing in creating experiences, design and communications.',
    tech: 'HTML5, CSS3, Javascript,',
    imgs: [
      {
        img: imgOgl3,
        alt: 'Home'
      },
      {
        img: imgOgl1,
        alt: 'About'
      },
      {
        img: imgOgl2,
        alt: 'Awards'
      },
      {
        img: imgOgl4,
        alt: 'Timeline'
      },
    ]
  },
  {
    id: 'eta',
    img: thumbGlacier,
    title: 'ETA',
    subTitle: 'Web App',
    project: 'Trip Planner',
    slug: 'expedition-travel-advisor',
    description: 'Entertainment Travel Advisor specializes in tailoring high-end vacations with the help of some of the world\'s best cruise lines. As Lead UI Engineer on the project, I managed junior developers, while contributing the overall design, architecture, and front- end engineering.',
    tech: 'React, Redux, Node.js',
    imgs: [
      {
        img: imgEta1,
        alt: 'Home'
      },
      {
        img: imgEta2,
        alt: 'Sign Up'
      },
      {
        img: imgEta3,
        alt: 'Destination'
      },
      {
        img: imgEta4,
        alt: 'Saved Destinations'
      },
      {
        img: imgEta5,
        alt: 'Message UI'
      },
    ]
  },
  {
    id: 'oss',
    img: thumbSpace,
    title: 'Open Source',
    subTitle: 'Design & Dev',
    project: 'Development Dashboards',
    slug: 'development-dashboards',
    description: 'A collection of open-source dashboards to organize and intuitively display your dev server and tooling output. It was my privilege to contribute to Formidable’s open-source efforts, providing the design of the Webpack Electron Dashboard and the design and engineering of the landing page.',
    tech: 'Sketch, Zeplin, React',
    imgs: [
      {
        img: imgOss1,
        alt: 'Marketing Site'
      },
      {
        img: imgOss3,
        alt: 'Webpack Electron Dashboard'
      },
      {
        img: imgOss2,
        alt: 'Dashboard Style Guide'
      }
    ]
  },
  {
    id: 'evo',
    img: thumbEvocalize,
    title: 'Evocalize',
    subTitle: 'Design',
    project: 'Angent App',
    slug: 'evocalize',
    description: 'Research shows that users respond more often when shown multiple, different ads over time. So, Evocalize generates multiple, effective target ads, and runs them intelligently. ',
    tech: 'Sketch, Zeplin, React Native',
    imgs: [
      {
        img: imgEvo4,
        alt: 'User Flow'
      },
      {
        img: imgEvo13,
        alt: 'Wireframe 1'
      },
      {
        img: imgEvo14,
        alt: 'Wireframe 2'
      },
      {
        img: imgEvo5,
        alt: 'Ad Generator'
      },
      {
        img: imgEvo6,
        alt: 'Promote Listing'
      },
      {
        img: imgEvo1,
        alt: 'Cart'
      },
      {
        img: imgEvo3,
        alt: 'Dashboard'
      },
      {
        img: imgEvo7,
        alt: 'Listings'
      },
      {
        img: imgEvo2,
        alt: 'Account'
      },
      {
        img: imgEvo8,
        alt: 'Style Guide'
      },
      {
        img: imgEvo9,
        alt: 'Style Guide'
      },
      {
        img: imgEvo10,
        alt: 'Style Guide'
      },
      {
        img: imgEvo11,
        alt: 'Style Guide'
      },
      {
        img: imgEvo12,
        alt: 'Style Guide'
      },
    ]
  },
  {
    id: 'meo',
    img: thumbOrtho,
    title: 'Orthopedics',
    subTitle: 'Engineering',
    project: 'Montefiore | Einstein',
    slug: 'montefiore-orthopedics',
    description: 'As the academic medical center and University Hospital for Albert Einstein College of Medicine, Montefiore Medical Center is nationally recognized for clinical excellence—breaking new ground in research, training the next generation of healthcare leaders, and delivering science-driven, patient-centered care.',
    tech: 'React, Drupal, Microservice API',
    imgs: [
      {
        img: imgME6,
        alt: 'Home'
      },
      {
        img: imgME5,
        alt: 'Find a Doctor'
      },
      {
        img: imgME3,
        alt: 'Find a Doctor Search'
      },
      {
        img: imgME4,
        alt: 'Find a Doctor Profile'
      }
    ]
  },
  {
    id: 'cov',
    img: thumbCovid19,
    title: 'Covid-19 Resource Center',
    subTitle: 'Engineering',
    project: 'Montefiore | Einstein',
    slug: 'montefiore-covid19',
    description: 'The latest information and guidance from Montefiore-Einstein experts on COVID-19 to help you keep physically and mentally strong.',
    tech: 'HTML/CSS, ES6, Drupal',
    imgs: [
      {
        img: imgME11,
        alt: 'Landing'
      },
      {
        img: imgME10,
        alt: 'Home'
      },
      {
        img: imgME7,
        alt: 'Vaccines'
      },
      {
        img: imgME9,
        alt: 'Scheduling'
      }
    ]
  },
  {
    id: 'can',
    img: thumbCancer,
    title: 'Cancer Center',
    subTitle: 'Engineering',
    project: 'Montefiore | Einstein',
    slug: 'montefiore-cancer-center',
    description: 'Our NCI-designated cancer center brings the worlds of clinical care and academic research together and allows for the translation of novel, cutting-edge scientific discoveries into improved clinical outcomes and new life-saving treatments.',
    tech: 'React, Drupal, Microservice API',
    imgs: [
      {
        img: imgME16,
        alt: 'Home'
      },
      {
        img: imgME13,
        alt: 'Find a Provider'
      },
      {
        img: imgME14,
        alt: 'Find a Provider: Results'
      },
      {
        img: imgME15,
        alt: 'Find a Provider: Profile'
      },
      {
        img: imgME12,
        alt: 'History'
      }
    ]
  }
]

export default data

//===================================================
// Archive
//===================================================
//
// import imgClub1 from '../images/thumbs/bg-club.png'
// {
//   id: clb,
//   thumb: thumbClub,
//   title: 'Club Nintendo',
//   subTitle: 'Website',
//   project: 'Landing Page',
//   slug: 'club-nintendo',
//   imgs: [
//     {
//       img: imgClb1,
//       alt: ''
//     }
//   ]
// },
//
//===================================================
