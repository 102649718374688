import React from 'react'
import styled from 'styled-components'
import { Link } from "@reach/router"

export const Teaser = styled(Link)`
  background-image: ${({ bg }) => bg ? `url(${bg})` : "transparent"};
  background-position: center;
  background-size: cover;
  border: 1rem solid #313131;
  border-radius: 0.2rem;
  font-family: 'Bebas Neue';
  text-align: center;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: 32%;
  position: relative;
  transition: all 0.5s;
  width: 100%;

  @media (min-width: 600px) {
    margin-bottom: 0;
    width: 32%;
  }
  
  @media (min-width: 900px) {
    &:before {
      align-items: center;
      bottom: 3rem;
      color: #907E63;
      content: ${({ project }) => project ? `"${project}"` : ""};
      display: flex;
      font-family: 'Ubuntu';
      font-size: 1.2rem;
      font-weight: 700;
      justify-content: center;
      left: 3rem;
      letter-spacing: 0.08em;
      opacity: 0;
      position: absolute;
      text-transform: uppercase;
      transition: opacity 0.5s;
      z-index: 10;
    }
  }


  &:after {
    align-items: center;
    background: rgba(0,0,0,0.8);
    bottom: 0;
    color: white;
    content: ${({ client }) => client ? `"${client}"` : ""};
    cursor: pointer;
    display: flex;
    font-size: 3.2rem;
    font-weight: 700;
    justify-content: center;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    text-transform: uppercase;
    top: 0;
    transition: opacity 0.5s;
    z-index: 5;
  }

  &:hover {
    border: 1rem solid #907E63;

    &:after,
    &:before {
      opacity: 1;
    }
  }
`

const WorkTeaser = ({ bg, client, project, slug }) => (
  <Teaser
    bg={bg}
    client={client}
    project={project}
    to={`/project/${slug}`}
  />
)

export default WorkTeaser
